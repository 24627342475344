module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Roboto Mono","Playfair Display"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Robbie Galvin","short_name":"RobbieGalvin","start_url":"/","background_color":"#07214F","theme_color":"#07214F","display":"standalone","icon":"src/images/icon.png","crossOrigin":"use-credentials","theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"9142f22278a79aa8c73597460a9f3615"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
